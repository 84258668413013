@font-face {
    font-family: 'Digital-7';
    src: url(./DS-DIGII.TTF) format('truetype');
  }
  
  /* digital clock cada numero deve ter um tamanho fixo*/



  .digital {
    display: inline-block;
    position: relative; 
    font-family: 'Digital-7';
    font-size: medium;
    color: #ebebeb;
    padding: 1px;
    
   

  }

  .digital .hour {
    width: 1.5em;
    text-align: center;
    display: inline-block;
    position: relative;
    font-size: 1.5rem;
    color: #ebebeb;
    padding: 1px;
  }

  
  

  @media (max-width: 770px) {

    .calendar, .ampm {
      font-size: 0.7rem;
    }
  
    .hour {
      font-size: 4rem;
    }
  
    .clock {
      padding: 5px;
    }
  
    .countdown {
      font-size: 4rem;
    }
    
  }