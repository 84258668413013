/* .playerDiv {
    width:100%;
    position: relative;
  } */

.wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0; 
    background-color: black;
}
  
.player {
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background-color: black;
} 
/*   
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 90% !important;
    background-color: rgba(0, 0, 0, 0.0);
    color: white;
    font-size: 24px;
}


.controls__div {
    
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    z-index: 2;
    display: none;
   
}

.overlay:hover .controls__div {
    visibility: visible;
    display: block;
    opacity: 1;
    
}

 

.controls__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.controls__icons {
   
    font-size: 50;
    transform:scale(0.9);
}

.controls__icons:hover {
    
    transform:scale(1);
}



.volume__slider {
    width: 20;
    margin-left: 150px;
    top:-40px;
}


.speed__input {
    width: 20;
    top:-20px;
   
} */

.wrapper .x {
    animation: x 60s linear infinite alternate;
    
  }
  
.wrapper .y {
    
    overflow-x:hidden; 
    opacity: 0.5;
    font-size: 8pt;
    font-family: Arial, sans-serif;
    animation: y 40s linear infinite alternate;
}

  
@keyframes x {
    100% {
      transform: translateX( calc(80vw - 200px) );
    }
}
  
@keyframes y {
    100% {
      transform: translateY( calc(80vh - 200px) );
    }
  }

.watermark {
    position: absolute;
    top: 10px;
    z-index: 1;
    animation: bounce 60s linear infinite alternate;
}

.watermark .x {
    animation: x 60s linear infinite alternate;
    
}

.watermark .y {
    
    overflow-x:hidden; 
    opacity: 0.5;
    font-size: 8pt;
    font-family: Arial, sans-serif;
    animation: y 40s linear infinite alternate;
}

/* 
  
  @keyframes bounce {
    100% {
        transform: translateX( calc(80vw - 200px) );
      }
      100% {
        transform: translateY( calc(80vh - 200px) );
      }
  } */